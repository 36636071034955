$COL1: #ffffff; //white
$COLFLAT1: #ffffff; //white
$COL2: #000000; //black
$COLFLAT2: #000000; //black
$COL3: #2b2e34; //primary
$COLFLAT3: #2b2e34; //primary
$COL4: #fdb825; //Secondary
$COLFLAT4: #fdb825; //Secondary
$COL5: #58585a; //dark grey
$COLFLAT5: #58585a; //dark grey
$COL6: #dddddd; //light grey
$COLFLAT6: #dddddd; //light grey
$COL7: #4c515b; //Shade 2
$COLFLAT7: #4c515b; //Shade 2
$COL8: #4c515b; //Grey 2
$COLFLAT8: #4c515b; //Grey 2
$COL9: rgba(0,0,0,0); //
$COLFLAT9: #808080; //
$COL10: #2b2e34; //
$COLFLAT10: #2b2e34; //
$COL11: #fdb825; //
$COLFLAT11: #fdb825; //
$COL12: #ffffff; //
$COLFLAT12: #ffffff; //
$COL13: #58585a; //
$COLFLAT13: #58585a; //
$COL14: #58585a; //
$COLFLAT14: #58585a; //
$COL15: #dddddd; //
$COLFLAT15: #dddddd; //
$COL16: #4c515b; //
$COLFLAT16: #4c515b; //
$COL17: #4c515b; //
$COLFLAT17: #4c515b; //
$COL18: #ffffff; //
$COLFLAT18: #ffffff; //
$COL19: #ffffff; //
$COLFLAT19: #ffffff; //
$COL20: #ffffff; //
$COLFLAT20: #ffffff; //
$COL21: #ffffff; //
$COLFLAT21: #ffffff; //
$COL22: #ffffff; //
$COLFLAT22: #ffffff; //
$COL23: #2b2e34; //
$COLFLAT23: #2b2e34; //
$COL24: #2b2e34; //
$COLFLAT24: #2b2e34; //
$COL25: #2b2e34; //
$COLFLAT25: #2b2e34; //
$COL26: #2b2e34; //
$COLFLAT26: #2b2e34; //
$COL27: #2b2e34; //
$COLFLAT27: #2b2e34; //
$COL28: #2b2e34; //
$COLFLAT28: #2b2e34; //
$COL29: #58585a; //
$COLFLAT29: #58585a; //
$COL30: #fdb825; //
$COLFLAT30: #fdb825; //
$COL31: #ffffff; //
$COLFLAT31: #ffffff; //
$COL32: #f2f2f2; //
$COLFLAT32: #f2f2f2; //
$COL33: #58585a; //
$COLFLAT33: #58585a; //
$COL34: rgba(255,255,255,0.31); //White trans
$COLFLAT34: #a7a7a7; //White trans
/* WARNING: Unable to find output for 1:base style (type=base)*/
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
