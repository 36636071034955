::selection {
background-color: $COL32;
color: $COL33;
}
::-moz-selection {
background-color: $COL32;
color: $COL33;
}
body { background-color: $COL12; }
body { color: $COL13; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL23;
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

}
h2 {
color: $COL24;
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 19px;

@media #{$medium-up} {
font-size: 18px;

}
}
h3 {
color: $COL25;
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

}
h4 {
color: $COL26;
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

}
h5 {
color: $COL27;
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

}
h6 {
color: $COL28;
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 16px;

}
.button {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
nav.mainmenu > .menu-item > div > a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

}
nav.secondarymenu > .menu-item > a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

}
p { padding-bottom: 1em; }
/* menu panel:2 */
.MES2 {
background-color: $COL3;
color: $COL1;
padding: 10px;

@media #{$medium-up} {
padding: 40px 25px;

}
@media #{$large-up} {
padding: 20px 25px;

}
 }
.MES2 {
background-color: $COL3;
color: $COL1;
padding: 10px;

@media #{$medium-up} {
padding: 40px 25px;

}
@media #{$large-up} {
padding: 20px 25px;

}
h1.MEC2, h2.MEC2, h3.MEC2, h4.MEC2, h5.MEC2, h6.MEC2 { color: $COL1;
 }
h1.MEC2 { font-size: 7.2px; }
h1.MEC2 { @media #{$medium-up} { font-size: 10.8px; }; }
h1.MEC2 { @media #{$large-up} { font-size: 18px; }; }
h2.MEC2 { font-size: 7.6px; }
h2.MEC2 { @media #{$medium-up} { font-size: 10.8px; }; }
h2.MEC2 { @media #{$large-up} { font-size: 18px; }; }
h3.MEC2 { font-size: 7.2px; }
h3.MEC2 { @media #{$medium-up} { font-size: 10.8px; }; }
h3.MEC2 { @media #{$large-up} { font-size: 18px; }; }
h4.MEC2 { font-size: 7.2px; }
h4.MEC2 { @media #{$medium-up} { font-size: 10.8px; }; }
h4.MEC2 { @media #{$large-up} { font-size: 18px; }; }
h5.MEC2 { font-size: 6.4px; }
h5.MEC2 { @media #{$medium-up} { font-size: 9.6px; }; }
h5.MEC2 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC2 { font-size: 6.4px; }
h6.MEC2 { @media #{$medium-up} { font-size: 9.6px; }; }
h6.MEC2 { @media #{$large-up} { font-size: 16px; }; }
 }
cite.MEC2{
color: $COL1;
}
/* New Style:3 */
.MES3 {
background-color: $COL4;
color: $COL1;
&:hover { color: $COL1;}
padding: 10px;

@media #{$medium-up} {
padding: 10px 20px;

}
 }
/* gey:4 */
.MES4 {
background-color: $COL5;
color: $COL1;
&:hover { color: $COL1;}
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
 }
/* home block grey:5 */
.MES5 {
background-color: $COL6;
color: $COL3;
padding: 20px 10px 20px 20px;

@media #{$large-up} {
padding: 20px 10px 20px 30px;

}
 }
.MES5 {
background-color: $COL6;
color: $COL3;
padding: 20px 10px 20px 20px;

@media #{$large-up} {
padding: 20px 10px 20px 30px;

}
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL2;
 }
h1.MEC5 { font-size: 7.2px; }
h1.MEC5 { @media #{$medium-up} { font-size: 10.8px; }; }
h1.MEC5 { @media #{$large-up} { font-size: 18px; }; }
h2.MEC5 { font-size: 7.6px; }
h2.MEC5 { @media #{$medium-up} { font-size: 10.8px; }; }
h2.MEC5 { @media #{$large-up} { font-size: 18px; }; }
h3.MEC5 { font-size: 7.2px; }
h3.MEC5 { @media #{$medium-up} { font-size: 10.8px; }; }
h3.MEC5 { @media #{$large-up} { font-size: 18px; }; }
h4.MEC5 { font-size: 7.2px; }
h4.MEC5 { @media #{$medium-up} { font-size: 10.8px; }; }
h4.MEC5 { @media #{$large-up} { font-size: 18px; }; }
h5.MEC5 { font-size: 6.4px; }
h5.MEC5 { @media #{$medium-up} { font-size: 9.6px; }; }
h5.MEC5 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC5 { font-size: 6.4px; }
h6.MEC5 { @media #{$medium-up} { font-size: 9.6px; }; }
h6.MEC5 { @media #{$large-up} { font-size: 16px; }; }
 }
cite.MEC5{
color: $COL3;
}
/* home block arrow:6 */
.MES6 {
background-color: $COL4;
color: $COL1;
padding: 20px 10px;

 }
.MES6 {
background-color: $COL4;
color: $COL1;
padding: 20px 10px;

h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL1;
 }
h1.MEC6 { font-size: 7.2px; }
h1.MEC6 { @media #{$medium-up} { font-size: 10.8px; }; }
h1.MEC6 { @media #{$large-up} { font-size: 18px; }; }
h2.MEC6 { font-size: 7.6px; }
h2.MEC6 { @media #{$medium-up} { font-size: 10.8px; }; }
h2.MEC6 { @media #{$large-up} { font-size: 18px; }; }
h3.MEC6 { font-size: 7.2px; }
h3.MEC6 { @media #{$medium-up} { font-size: 10.8px; }; }
h3.MEC6 { @media #{$large-up} { font-size: 18px; }; }
h4.MEC6 { font-size: 7.2px; }
h4.MEC6 { @media #{$medium-up} { font-size: 10.8px; }; }
h4.MEC6 { @media #{$large-up} { font-size: 18px; }; }
h5.MEC6 { font-size: 6.4px; }
h5.MEC6 { @media #{$medium-up} { font-size: 9.6px; }; }
h5.MEC6 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC6 { font-size: 6.4px; }
h6.MEC6 { @media #{$medium-up} { font-size: 9.6px; }; }
h6.MEC6 { @media #{$large-up} { font-size: 16px; }; }
 }
cite.MEC6{
color: $COL1;
}
/* Default:7 */
.MES7 {
background-color: $COL1;
color: $COL2;
padding: 10px;

@media #{$medium-up} {
padding: 0 30px;

}
@media #{$large-up} {
padding: 20px 40px;

}
 }
.MES7 {
background-color: $COL1;
color: $COL2;
padding: 10px;

@media #{$medium-up} {
padding: 0 30px;

}
@media #{$large-up} {
padding: 20px 40px;

}
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL3;
 }
 }
cite.MEC7{
color: $COL2;
}
/* Black:8 */
.MES8 {
background-color: $COL3;
color: $COL1;
&:hover { color: $COL1;}
padding: 10px;

@media #{$medium-up} {
padding: 10px 20px;

}
 }
/* Dark grey:9 */
.MES9 {
background-color: $COL5;
color: $COL1;
padding: 10px;

@media #{$medium-up} {
padding: 40px 25px;

}
 }
.MES9 {
background-color: $COL5;
color: $COL1;
padding: 10px;

@media #{$medium-up} {
padding: 40px 25px;

}
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL1;
 }
h1.MEC9 { font-size: 7.2px; }
h1.MEC9 { @media #{$medium-up} { font-size: 10.8px; }; }
h1.MEC9 { @media #{$large-up} { font-size: 18px; }; }
h2.MEC9 { font-size: 7.6px; }
h2.MEC9 { @media #{$medium-up} { font-size: 10.8px; }; }
h2.MEC9 { @media #{$large-up} { font-size: 18px; }; }
h3.MEC9 { font-size: 7.2px; }
h3.MEC9 { @media #{$medium-up} { font-size: 10.8px; }; }
h3.MEC9 { @media #{$large-up} { font-size: 18px; }; }
h4.MEC9 { font-size: 7.2px; }
h4.MEC9 { @media #{$medium-up} { font-size: 10.8px; }; }
h4.MEC9 { @media #{$large-up} { font-size: 18px; }; }
h5.MEC9 { font-size: 6.4px; }
h5.MEC9 { @media #{$medium-up} { font-size: 9.6px; }; }
h5.MEC9 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC9 { font-size: 6.4px; }
h6.MEC9 { @media #{$medium-up} { font-size: 9.6px; }; }
h6.MEC9 { @media #{$large-up} { font-size: 16px; }; }
 }
cite.MEC9{
color: $COL1;
}
/* responsive header:10 */
.MES10 {
background-color: $COL3;
color: $COL1;
padding: 20px;

 }
.MES10 {
background-color: $COL3;
color: $COL1;
padding: 20px;

h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: $COL1;
 }
h1.MEC10 { font-size: 7.2px; }
h1.MEC10 { @media #{$medium-up} { font-size: 10.8px; }; }
h1.MEC10 { @media #{$large-up} { font-size: 18px; }; }
h2.MEC10 { font-size: 7.6px; }
h2.MEC10 { @media #{$medium-up} { font-size: 10.8px; }; }
h2.MEC10 { @media #{$large-up} { font-size: 18px; }; }
h3.MEC10 { font-size: 7.2px; }
h3.MEC10 { @media #{$medium-up} { font-size: 10.8px; }; }
h3.MEC10 { @media #{$large-up} { font-size: 18px; }; }
h4.MEC10 { font-size: 7.2px; }
h4.MEC10 { @media #{$medium-up} { font-size: 10.8px; }; }
h4.MEC10 { @media #{$large-up} { font-size: 18px; }; }
h5.MEC10 { font-size: 6.4px; }
h5.MEC10 { @media #{$medium-up} { font-size: 9.6px; }; }
h5.MEC10 { @media #{$large-up} { font-size: 16px; }; }
h6.MEC10 { font-size: 6.4px; }
h6.MEC10 { @media #{$medium-up} { font-size: 9.6px; }; }
h6.MEC10 { @media #{$large-up} { font-size: 16px; }; }
 }
cite.MEC10{
color: $COL1;
}
/* Shade 2:11 */
.MES11 {
background-color: $COL7;
color: $COL1;
padding: 10px;

@media #{$medium-up} {
padding: 40px 25px;

}
@media #{$large-up} {
padding: 20px 25px;

}
 }
.MES11 {
background-color: $COL7;
color: $COL1;
padding: 10px;

@media #{$medium-up} {
padding: 40px 25px;

}
@media #{$large-up} {
padding: 20px 25px;

}
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: $COL1;
 }
 }
cite.MEC11{
color: $COL1;
}
/* Grey 2:12 */
.MES12 {
background-color: $COL7;
color: $COL1;
&:hover { color: $COL1;}
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
 }
/* Primary:14 */
.MES14 {
background-color: $COL10;
color: $COL18;
 }
/* Primary:15 */
.MES15 {
background-color: $COL10;
color: $COL18;
 }
.MES15 {
background-color: $COL10;
color: $COL18;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL18;
 }
 }
cite.MEC15{
color: $COL18;
}
/* Secondary:16 */
.MES16 {
background-color: $COL11;
color: $COL19;
 }
/* Secondary:17 */
.MES17 {
background-color: $COL11;
color: $COL19;
 }
.MES17 {
background-color: $COL11;
color: $COL19;
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: $COL19;
 }
 }
cite.MEC17{
color: $COL19;
}
/* Alternate:18 */
.MES18 {
background-color: $COL14;
color: $COL20;
 }
/* Alternate:19 */
.MES19 {
background-color: $COL14;
color: $COL20;
 }
.MES19 {
background-color: $COL14;
color: $COL20;
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL20;
 }
 }
cite.MEC19{
color: $COL20;
}
/* Light:20 */
.MES20 {
background-color: $COL15;
 }
/* Light:21 */
.MES21 {
background-color: $COL15;
 }
.MES21 {
background-color: $COL15;
 }
/* Dark:22 */
.MES22 {
background-color: $COL16;
color: $COL21;
 }
/* Dark:23 */
.MES23 {
background-color: $COL16;
color: $COL21;
 }
.MES23 {
background-color: $COL16;
color: $COL21;
h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: $COL21;
 }
 }
cite.MEC23{
color: $COL21;
}
/* Shade 2:24 */
.MES24 {
background-color: $COL17;
color: $COL22;
 }
/* Shade 2:25 */
.MES25 {
background-color: $COL17;
color: $COL22;
 }
.MES25 {
background-color: $COL17;
color: $COL22;
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: $COL22;
 }
 }
cite.MEC25{
color: $COL22;
}
/* :26 */
nav.me-Menu.MES26 {
& .menu-item.MEC26, & .menu-item.MEC26 > div.MEC26{ & > a.MEC26{color: $COL6;
}
 &:hover > a.MEC26{color: $COL12;
}
 }
&.horizontal > .menu-item.MEC26 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC26 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC26 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC26 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* :27 */
.MES27 {
padding: 10px 0;

& > hr {border-width: 0 0 1px 0;}
& > hr {border-color: $COL15;}
 }
/* Accordion:28 */
details.MES28 {
& > summary{background-color: $COL29;
&:hover {background-color: $COL30;}
}
color: $COL10;
& > article { color: $COL10;
 }
& > summary{padding: 10px 20px;}

& > summary { color: $COL31;
 }
& > article a { color: $COL4;
 }
& > article li { color: $COL3;
 }
 }
/* Highlight:29 */
.MES29 {
background-color: $COL32;
color: $COL33;
 }
/* Highlight:30 */
.MES30 {
background-color: $COL32;
color: $COL33;
 }
.MES30 {
background-color: $COL32;
color: $COL33;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: $COL33;
 }
 }
cite.MEC30{
color: $COL33;
}
/* :31 */
.MES31 {
& .slider-arrow {background-color: $COL9;
color: $COL34;
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 100px;
};
} }
/* :32 */
.MES32 {
 }
